import React from "react"
import { Link } from "gatsby"

    const isPartiallyActive = ({
      isPartiallyCurrent
    }) => {
      return isPartiallyCurrent
        // ? { className: "active-header"}
        ? { style: {color: `#000`}}
        : {}
    }

    // const PartialNavLink = props => (
    //   <Link getProps={isPartiallyActive} {...props} />
    // )

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `0.5rem` }}>
    <h2 className="header-link"><Link getProps={isPartiallyActive} to={props.to}>{props.children}</Link></h2>
  </li>
)


export default function Layout({ children }) {
  return (
    <div style={{padding: `0 0.5rem`}}>
     <header className="portfolio-header-home">
       <h2 className="header-link" style={{ display: `inline` }}>
        <Link to="/" style={{ textDecoration: `none`, textShadow: `none`, backgroundImage: `none` }}>
         Malavika
       </Link>
       </h2>
       <ul className="header-right">
          <ListLink to="/work/"> Work, </ListLink>
          <ListLink to="/notes/"> Notes, </ListLink>
          <ListLink to="/about/"> About </ListLink>
       </ul>
     </header>
     <div className="portfolio-content">
     {children}
    </div>
   </div>
  )
}
