import React from "react"
import { Link } from "gatsby"


export default function Footer({next, prev}) {
  return (
    <div style={{padding: `3rem 0 0 0`}}>
     <span className="portfolio-footer">
     <h2>
      { prev && <Link to={prev.frontmatter.slug} style={{ textDecoration: `none`, textShadow: `none`}}>Previous Project </Link> }
      ––
      { next && <Link to={next.frontmatter.slug} style={{ textDecoration: `none`, textShadow: `none`}}> Next Project</Link> } 

      <Link className="nav-right" to="../" style={{ textDecoration: `none`, textShadow: `none`}}>
         Back To Projects
      </Link>
    </h2>
   </span>
   </div>
  )
}
