import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout-portfolio"
import Footer from "../components/footer-portfolio"
import { Helmet } from "react-helmet"
import "../components/portfolio.css"
import { Link } from "gatsby"

export default function Template({data, pageContext}) {
  // console.log(pageContext);
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const {next, prev} = pageContext

  const taglength = frontmatter.tags.length

  return (
    <Layout>
    <Helmet>
      <title>
        {frontmatter.title}
      </title>
    </Helmet>
    <div className="portfolio-post-container">
      <div className="portfolio-post">
        <h1 className="portfolio-title">{frontmatter.title}</h1>
        <div className="portfolio-metadata">
          <div className="portfolio-description">{frontmatter.description}</div>
          <div className="portfolio-tags">{
              frontmatter.tags.map((tag,index) =>
              {
                return (
                  <span>
                  <Link className="tag-link" to={`../tags/${tag}`}>
                  {tag}
                  </Link>
                  { (index < taglength-1) ? ', ' : '' }
                  </span>
                )
              })
            }
          </div>
          <div className="portfolio-year">{frontmatter.year}</div>
        </div>


        <div
          className="portfolio-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
    <Footer next={next} prev={prev}></Footer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      frontmatter {
        is_portfolio
        slug
        tags
        title
        year
        description
        aliases
      }
    }
  }
`
